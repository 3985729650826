<template>
  <div class="page">
    <div class="top">
      <div class="top_title">
        <i class="el-icon-back" style="font-size: 20px" @click="goBack"></i>
        <span style="margin-left: 20px">返回</span>
      </div>
      <div>*为必填项</div>
    </div>

    <el-divider></el-divider> 

    <el-form :model="formInfo" ref="addForm" label-width="180px" label-position="right" style="width:90%;" :inline="true">
      <div class="li">
        <div class="content_title">
          <span>人员基本信息</span>
        </div>
        <div>
          <el-form-item style="width:45%;" label="姓名：" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-input v-model.trim="formInfo.name" placeholder="请输入" maxlength="10" />
          </el-form-item>
          <el-form-item style="width:45%;" label="证件类型：" prop="cardType" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-select class="select-width" size="medium" v-model="formInfo.cardType" placeholder="证件类型" clearable>
              <el-option v-for="(item, v) in cardTypeList" :key="v" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="width:45%;" label="证件号码：" prop="cardNo" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-input v-model.trim="formInfo.cardNo" placeholder="请输入证件号码"
            @blur="idCodeCount(formInfo.cardNo)"  />
            
          </el-form-item>
          <el-form-item style="width:45%;" label="性别：" prop="sex" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-radio-group v-model="formInfo.sex">
              <el-radio label="0">女</el-radio>
              <el-radio label="1">男</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="width:45%;" label="年龄：" prop="age" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-input v-model.trim="formInfo.age" placeholder="请输入"  />
          </el-form-item>
          <el-form-item style="width:45%;" label="联系电话：" prop="linkPhone" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-input v-model.trim="formInfo.linkPhone" placeholder="请输入"  />
          </el-form-item>
          <el-form-item style="width:45%;" label="国籍：" prop="country" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <!-- <el-input v-model.trim="formInfo.country" placeholder="请输入"  /> -->
            <el-select filterable placeholder="请选择国籍" class="select-width" v-model="formInfo.country">
                <el-option v-for="item in nationalityselet" :key="item.label" :label="item.label" :value="item.label">
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{
                        item.value
                    }}
                  </span>
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item style="width:45%;" label="民族：" prop="nation" >
            <!-- <el-input v-model.trim="formInfo.nation" placeholder="请输入"  /> -->
            <el-select class="top_left_inputnationality select-width" v-model="formInfo.nation" placeholder="民族">
                <el-option v-for="item in nationSelect" :key="item.dictCode" :label="item.dictLabel"
                  :value="item.dictLabel"></el-option>
              </el-select>
          </el-form-item>
          
          <el-form-item style="width:45%;" label="人群分类：" prop="crowdTypeId" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-select @change="rqchange"  v-model="formInfo.crowdTypeId" placeholder="选择人群分类" style="width:200px;" >
              <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"  :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="li">
        <div class="content_title">
          <span>调查信息</span>
        </div>
        <div>
          <el-form-item style="width:45%;" label="旅居地区：" prop="livingAreaCode" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <!-- <el-input v-model.trim="formInfo.livingArea" placeholder="请输入"  /> -->
            <el-cascader class="select-width" ref="surveyObjectBelongAreaRef"
                v-model="formInfo.livingAreaCode"  :props="props"
                v-if="wzShow"
                @change="handelSurveyObjectBelongArea" clearable></el-cascader>
          </el-form-item>
          <el-form-item style="width:45%;" label="风险等级：" prop="riskLevel" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-select v-model="formInfo.riskLevel" style="width:300px;" placeholder="请选择">
              <el-option v-for="item in fxoptions" :key="item.value" :label="item.value" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item style="width:45%;" label="来渝交通工具：" prop="traffic" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-select v-model="formInfo.traffic" style="width:300px;" placeholder="请选择">
              <el-option v-for="item in trafficselect" :key="item.dictSort" :label="item.dictLabel" :value="item.dictLabel" />
            </el-select>
          </el-form-item>
          <el-form-item style="width:45%;" label="航班号/车次号/车牌号：" prop="trafficNo" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-input v-model.trim="formInfo.trafficNo" placeholder="航班号/车次号/车牌号" style="width:200px" />
            <el-input v-model.trim="formInfo.seatNo" placeholder="座位号" style="width:100px" />
          </el-form-item>
          <el-form-item style="width:45%;" label="离开风险区时间：" prop="leaveTime" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-date-picker
              v-model="formInfo.leaveTime"
              size="medium"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期" />
          </el-form-item>
          <el-form-item style="width:45%;" label="抵渝时间：" prop="arrivalTime" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-date-picker
              v-model="formInfo.arrivalTime"
              size="medium"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期" />
          </el-form-item>
          <el-form-item style="width:45%;" label="户籍地：" prop="householdPlaceCode" >
            <!-- <el-input v-model.trim="formInfo.householdPlace" placeholder="请输入"  /> -->
            <el-cascader class="select-width" ref="surveyObjectBelongAreaRef2"
                v-model="formInfo.householdPlaceCode"  :props="props"
                v-if="wzShow"
                @change="handelSurveyObjectBelongArea2" clearable></el-cascader>
          </el-form-item>
          <el-form-item style="width:45%;" label="目的地：" prop="destinationCode"  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-cascader class="select-width" ref="surveyObjectBelongAreaRef3"
                v-model="formInfo.destinationCode"  :props="props"
                v-if="wzShow"
                @change="handelSurveyObjectBelongArea3" clearable></el-cascader>
          </el-form-item>
          <el-form-item style="width:45%;" label="所属街道：" prop="destinationStreetCode" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-select @change="streetOrgChange"  v-model="formInfo.destinationStreetCode" placeholder="选择所属街道" style="width:200px;" clearable>
              <el-option v-for="item in streetOrgSelect" :key="item.value" :label="item.label"  :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width:45%;" label="居住地址：" prop="currentAddressDetail" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
            <el-input v-model.trim="formInfo.currentAddressDetail" placeholder="请填写县（区）乡镇（街镇）村居（社区）" style="width:300px;"/>
          </el-form-item>
        </div>
      </div>
      <div class="li">
        <div class="content_title">
          <span>健康状况</span>
        </div>
        <div>
          <el-form-item style="width:100%;" label="是否有基础性疾病：" prop="basicIllnessFlag" >
            <el-radio-group v-model="formInfo.basicIllnessFlag">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
              <el-radio label="3">不详</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item style="width:100%;" label="基础性疾病名称：" prop="basicIllnessList" >
            <!-- <el-checkbox-group v-model="formInfo.basicIllness">
              <el-checkbox label="1">启用</el-checkbox>
              <el-checkbox label="2">停用</el-checkbox>
            </el-checkbox-group> -->
            <el-select
              style="width:500px;"
              v-model="formInfo.basicIllnessList"
              @change="changeFun"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择疾病名称">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.value"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="width:100%;" label="人员类型：" prop="personType" >
            <el-radio-group v-model="formInfo.personType">
              <el-radio label="0">正常 </el-radio>
              <el-radio label="1">陪护的儿童</el-radio>
              <el-radio label="2">老年人</el-radio>
              <el-radio label="3">无自理能力的病患</el-radio>
              <el-radio label="4">孕产妇</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item style="width:100%;" label="备注:"  prop="remark" >
            <el-input style="width:600px;" width="100%" type="textarea" v-model="formInfo.remark" :rows="5" placeholder="请输入内容" maxlength="200"  show-word-limit></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="footer">
        <el-button v-if="dialogFormVisibleType!='see'" type="primary" @click="dialogSubmit('addForm')">提  交</el-button>
      </div>

    </el-form>
  </div>
</template>

<script type="text/javascript">
import { _debounce } from '@/utils/utils.js'
import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import { http } from "@/api/index";
import constants from '@/common/constants';
import { getIdNumberInfo } from "@/utils/utils";
import {gettrafficway} from "../../api/Taskmanagement/upcomingTasks";
import {getSubList} from "@/api/SystemManagement/index"
import {
    getRoleRes,
    streetregSave,
    streetUpdate,
  } from "@/api/streetSegregation/index.js";
    import {
    mapState
  } from "vuex";
const formObj = { //表单
    name: "",
    cardType: "1",
    cardNo: "",
    sex: "",
    age: "",
    linkPhone: "",
    country: "",
    nation: "",
    currentAddressDetail: "",
    crowdType:"",
    crowdTypeName:"",
    crowdTypeId: "",

    livingArea: "",
    livingAreaCode:"",
    riskLevel: "",
    traffic: "",
    trafficNo: "",
    seatNo:"",

    leaveTime: "",
    arrivalTime: "",
    householdPlace: "",
    householdPlaceCode: "",
    destination: "",
    destinationCode:"",
    destinationStreetCode:"",
    destinationStreetName:"",

    basicIllnessFlag: "",
    basicIllnessList:[],
    basicIllness:"",
    personType: "",
    remark: "",
  };
export default {
  name: "page",
  props:['dialogFormVisibleId','dialogFormVisibleType'],
  data() {
    return {

      //3级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map(item => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3
              }));
              resolve(nodes);
            });
          }
        }
      },
      formInfo: formObj,

      perTypeList : [ //表单
        {
          value: "1",
          label: "社区人员"
        },
        {
          value: "2",
          label: "司机"
        },
        {
          value: "3",
          label: "隔离酒店人员"
        },
        {
          value: "4",
          label: "疾控中心人"
        },
        {
          value: "5",
          label: "转运调度人员"
        },
      ],

      cardTypeList : [ //表单
        {
          value: "1",
          label: "居民身份证"
        },
        {
          value: "2",
          label: "护照"
        },
        {
          value: "3",
          label: "港澳通行证"
        },
        {
          value: "4",
          label: "台胞证"
        },
        {
          value: "5",
          label: "其他"
        },
      ],

      typepopSelect:[],
      streetOrgSelect:[],
      options:[
        {
          value: '高血压'
        }, {
          value: '肺气肿'
        }, {
          value: '免疫缺陷'
        }, {
          value: '糖尿病'
        }, {
          value: '慢性支气管炎'
        }, {
          value: '艾滋病'
        }, {
          value: '脑血管病'
        }, {
          value: '肺癌'
        }, {
          value: '肺结核'
        }, {
          value: '冠心病'
        }, {
          value: '慢性肝病'
        }, {
          value: '妊娠'
        }, {
          value: '哮喘'
        }, {
          value: '慢性肾病'
        }, {
          value: '其他'
        }
      ],

      fxoptions:[
        {
          value: '中风险'
        }, {
          value: '高风险'
        }, {
          value: '低风险'
        }
      ],
      trafficselect:[],
      wzShow:true,
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
    };
  },
  components: {},
  computed: {
    ...mapState({
      nationSelect: state => state.user.nationSelect // 民族
    })
  },
  watch: {
    streetOrgSelect(){
      if(this.streetOrgSelect === []){
        this.streetOrgSelect = []
      }
    }
  },
  created() {
    this.formInfo = Object.assign({},formObj);
    this.getCrowdTypeList(); //人群
    if(this.dialogFormVisibleType == 'set' || this.dialogFormVisibleType == 'see'){
      this.getRoleResFun();
    };
    this.getTraffic(); //获取交通工具
  },
  beforeDestroy() {
   
  },
  methods: {

    // 获取交通工具
    async getTraffic() {
        let res = await gettrafficway();
        if(res.status==200){
          this.trafficselect = res.data.data;
        }
      },

    // 身份证计算
    idCodeCount(id) {
      if (this.formInfo.cardType == 1) {
        if (id && id.length == 18) {
          let obj = getIdNumberInfo(id);
          this.formInfo.sex = obj.sex == 0 ? "1" : "0";
          this.formInfo.age = obj.age;
        } else {
          this.formInfo.sex = "";
          this.formInfo.age = "";
        }
      }
    },

    changeFun(){
      this.$forceUpdate();
    },
    //人群分类事件
    rqchange(val){
      let obj = this.typepopSelect.find((item)=>{
        return item.id == val
      })
      this.formInfo.crowdTypeName = obj.ruleName;
      this.formInfo.crowdType = obj.code;
    },
    streetOrgChange(val){
      let obj = this.streetOrgSelect.find((item)=>{
        return item.value == val
      })
      if(obj === undefined) return
      this.formInfo.destinationStreetName = obj.label;
      this.formInfo.destinationStreetCode = obj.value;
    },

    handelSurveyObjectBelongArea(value) {
      let node = this.$refs.surveyObjectBelongAreaRef.getCheckedNodes()[0];
      if (node) {
        let surveyObjectBelongArea = node.pathLabels.toString();
        this.formInfo.livingArea = surveyObjectBelongArea;
        this.formInfo.livingAreaCode = value;
      }
    },
    handelSurveyObjectBelongArea2(value) {
      let node = this.$refs.surveyObjectBelongAreaRef2.getCheckedNodes()[0];
      if (node) {
        let surveyObjectBelongArea = node.pathLabels.toString();
        this.formInfo.householdPlace = surveyObjectBelongArea;
        this.formInfo.householdPlaceCode = value;
      }
    },
    handelSurveyObjectBelongArea3(value) {
      this.streetOrgSelect = []
      let node = this.$refs.surveyObjectBelongAreaRef3.getCheckedNodes()[0];
      if (node) {
        let surveyObjectBelongArea = node.pathLabels.toString();  // 省市区 北京市,市辖区,西城区
        this.formInfo.destination = surveyObjectBelongArea;    // 省市区 北京市,市辖区,西城区
        this.formInfo.destinationCode = value;        //value经纬度 数组字符串
        this.getStreetList(this.formInfo.destinationCode[2]);
      }
    },
    // 返回上一页面
    goBack() {
      this.$parent.dialogFormVisible = false;
    },

    // 人群分类
      async getCrowdTypeList() {
        const { data } = await getCrowdTypeList();
        if (data.code == "200") {
          this.typepopSelect = data.data;
        }
      },
      // 街道
      async getStreetList(label) {
        let params = {
          label: label, //三级的编码
        }
        const { data } = await getSubList(params); //获取街道下拉框数据
        if (data.code == "200") {
          this.streetOrgSelect = data.data;
        }
      },

      getRoleResFun(){
        this.wzShow = false;
        let params = {id:this.dialogFormVisibleId};
        getRoleRes(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.formInfo = data;
            this.formInfo.basicIllnessList = this.formInfo.basicIllness? this.formInfo.basicIllness.split(',') :[];
            this.formInfo.livingAreaCode = this.formInfo.livingAreaCode? this.formInfo.livingAreaCode.split(","): [];
            this.formInfo.householdPlaceCode = this.formInfo.householdPlaceCode? this.formInfo.householdPlaceCode.split(","): [];
            this.formInfo.destinationCode = this.formInfo.destinationCode? this.formInfo.destinationCode.split(","): [];
            this.wzShow = true;
            this.getStreetList(this.formInfo.destinationCode[2]);
          }
        })
      },

    //表单新增
      dialogSubmit:_debounce(function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.submitFormFun();
          } else {
            return false;
          }
        });
      },300),

      submitFormFun() {
        
        let params = Object.assign({}, this.formInfo);
        params.basicIllness = (params.basicIllnessList && params.basicIllnessList.length > 0)?params.basicIllnessList.toString(): "";
        params.livingAreaCode = params.livingAreaCode.length > 0? params.livingAreaCode.toString(): "";
        params.householdPlaceCode = params.householdPlaceCode.length > 0? params.householdPlaceCode.toString(): "";
        params.destinationCode = params.destinationCode.length > 0? params.destinationCode.toString(): "";
        var API = this.dialogFormVisibleType == 'add' ? streetregSave : streetUpdate;
        API(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.$parent.dialogFormVisible = false;
            this.$message({
              type: 'success',
              message: '保存成功!'
            });
            this.$parent.getUserInfo();
            
          }
        })
      },

  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  background-color: #fff;

  ::v-deep {
    .el-form-item__label {
      width: 90px;
    }
  }
}
.top {
  display: flex;
  justify-content: space-between;
}

.top_title {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #666666;
}

.content_title {
  padding: 20px 0;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: 14px;

  &:after {
    content: "";
    width: 5px;
    height: 23px;
    background: #409eff;
    position: absolute;
    top: 22px;
    left: -12px;
  }
}
.footer{
  display: flex;
  justify-content: center;
  padding: 20px 0 40px;
}
</style>